window.__tnt || (window.__tnt = {});
__tnt.map || (__tnt.map = {});
__tnt.map.instances || (__tnt.map.instances = []);

__tnt.map.here = (function(){
    var Here = function(options) {
        var lib = {};

        // private properties
        var locations = [];
        var map_id = null;
        var app_id = null;
        var app_code = null;
        var api_key = null;
        var map = null;
        var markers = [];
        var local_storage_supported = __tnt.client.capabilities.supportsLocalStorage();
        var stored_locations = [];
        var is_static = false;
        var map_settings = {};
        var icon_fill = null;
        var use_latlng = false;
        var max_batch = 3;
        var batch_delay = 2000;

        // private methods
        var getLocationsFromStorage = function() {
            if (local_storage_supported) {
                if (localStorage.getItem('tnt-last-geocode')) {
                    var last_geocode = JSON.parse(localStorage.getItem('tnt-last-geocode'));
                    if (Date.now() > last_geocode + 604800000) { // last geocode was longer than 7 days ago
                        localStorage.removeItem('tnt-geocoded-locations');
                        localStorage.removeItem('tnt-last-geocode');
                    }
                }
                if (localStorage.getItem('tnt-geocoded-locations')) {
                    stored_locations = JSON.parse(localStorage.getItem('tnt-geocoded-locations'));
                }
            }
        };

        var createMap = function(map_id, app_id, app_code, is_static) {
            if (is_static) {
                map_settings = {
                    dragging: false,
                    zoomControl: false,
                    boxZoom: false,
                    doubleClickZoom: false,
                    scrollWheelZoom: false
                };
            } else {
                map_settings = {
                    gestureHandling: true
                };
            }
            map = new L.map(map_id, map_settings);
            L.tileLayer.here({
                apiKey: api_key,
                appId: app_id,
                appCode: app_code,
                scheme: 'normal.day'
            }).addTo(map);
            
            getCoords();
        };

        var getCoords = function(index, batch_index) {
            var i = index || 0;
            var bi = batch_index || 0;
            var stored_location = null;

            if (locations[i].address && local_storage_supported) {
                stored_location = loadGeocode(locations[i].address);
            }

            if (stored_location) {
                locations[i].lat = stored_location.lat;
                locations[i].lon = stored_location.lon;
                plotLocation(locations[i]);
                i++;
                if (i < locations.length) getCoords(i, bi);

            } else if (
                (locations[i].lat && locations[i].lon && !locations[i].address)
                || (use_latlng && locations[i].lat && locations[i].lon)
            ) {
                plotLocation(locations[i]);
                i++;
                if (i < locations.length) getCoords(i, bi);

            } else {
                geocode(locations[i]).then(function(data){
                    locations[i].lat = data.items[0].position.lat;
                    locations[i].lon = data.items[0].position.lng;
                    if (local_storage_supported) {
                        saveGeocode(locations[i].address, locations[i].lat, locations[i].lon);
                    }
                    plotLocation(locations[i]);
                    i++;
                    bi++;
                    if (i < locations.length) {
                        if (bi >= max_batch) {
                            bi = 0;
                            setTimeout(getCoords, batch_delay, i, bi);
                        } else {
                            getCoords(i, bi);
                        }
                    }
                });
            }
        };

        var geocode = function(location) {
            var geocode_url;
            var address = location.address.replace(/\s+/g,'+').replace(/,/g,'');
            address = encodeURI(address);
            if (api_key) {
                geocode_url = 'https://geocode.search.hereapi.com/v1/geocode?q='+address+'&apiKey='+api_key;
            } else {
                geocode_url = 'https://geocoder.api.here.com/6.2/geocode.json?jsonattributes=1&searchtext='+address+'&app_id='+app_id+'&app_code='+app_code;
            }
            return fetch(geocode_url).then(function(response) {
                if (response.status !== 200) {
                    __tnt.log('Maps error: ' + response.status);
                    return;
                }
                return response.json();
            });
        };

        var plotLocation = function(location) {
            if (location.lat && location.lon) {
                createMarker(location);
                var group = new L.featureGroup(markers).addTo(map);
                map.fitBounds(group.getBounds(), {maxZoom:14, padding:[30,30]});
            }
        };

        var createMarker = function(location) {
            var label = (location.label) ? location.label : null;
            var fill_color = (location.icon_fill) ? location.icon_fill : (icon_fill ? icon_fill : '#EA4335');
            var classes = (location.icon_classes) ? location.icon_classes : null;
            var markerOptions = {iconOptions: {text:label, fillColor:fill_color, customClasses:classes}};
            
            var marker = new L.Marker.SVGMarker([location.lat, location.lon], markerOptions);
            
            if (!is_static && location.html) {
                marker.bindPopup(location.html);
            }
            markers.push(marker);
        };

        var saveGeocode = function(address, lat, lon) {
            var new_location = {
                'address': address,
                'lat': lat,
                'lon': lon
            };
            
            if (stored_locations.length > 99) {
                stored_locations.splice(0, 1);
            }
            stored_locations.push(new_location);
            localStorage.setItem('tnt-geocoded-locations', JSON.stringify(stored_locations));
            
            if (!localStorage.getItem('tnt-last-geocode')) {
                localStorage.setItem('tnt-last-geocode', Date.now());
            }
        };

        var loadGeocode = function(address) {
            for (var i=0; i<stored_locations.length; i++) {
                if (stored_locations[i].address === address) {
                    return stored_locations[i];
                }
            }
            return null;
        };

        // public properties
        lib.id = null;

        // public methods
        lib.init = function(options) {
            this.id = options.id;
            locations = options.locations;
            app_id = options.app_id;
            app_code = options.app_code;
            api_key = options.api_key;
            map_id = options.map_id;
            is_static = options.static || false;
            icon_fill = options.icon_fill;
            use_latlng = (options.use_latlng) ? true : false;
            getLocationsFromStorage();
            createMap(map_id, app_id, app_code, is_static);
            __tnt.map.instances[__tnt.map.instances.length] = this;

            window.addEventListener('tnt-user-status', function(){
                map.invalidateSize();
            });
        };

        lib.resetView = function() {
            var group = new L.featureGroup(markers);
            map.fitBounds(group.getBounds(), {maxZoom:14});
        };

        lib.focusMarker = function(i) {
            if (i <= markers.length - 1) {
                map.flyTo(markers[i].getLatLng(), 15);
            }
        };

        lib.invalidateSize = function() {
            map.invalidateSize();
        };

        lib.init(options);

        return lib;
    };

    return Here;
})();